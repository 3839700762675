import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Customer, Payment, Plan, TrainingProgramBasicInfo } from '@models';
import { EndpointsService } from '@services/endpoints.service';


@Injectable({
  providedIn: 'root'
})
export class PurchaseService {
  private lastPurchase: {
    plan: Plan,
    program: TrainingProgramBasicInfo,
    customer: Customer,
    payment: Payment,
  };

  constructor(private http: HttpClient, private endpointsService: EndpointsService) { }

  recurrentPurchase(id: number, customer: Customer, payment: Payment) {
    const body = { id, customer: customer, payment: payment };
    return this.http.post<{purchase: Purchase, purchaseStatus, merchantOrderId}>
      (this.endpointsService.get('purchase.recurrentPurchase'), body);
  }

  simplePurchase(id: number, customer: Customer, payment: Payment) {
    const body = { id, customer: customer, payment: payment };
    return this.http.post<{purchase: Purchase, purchaseStatus, merchantOrderId}>
      (this.endpointsService.get('purchase.simplePurchase'), body);
  }

  purchase(code: string, customer: Customer, payment: Payment, recaptcha: string) {
    const body = {
      productCode: code,
      customer: {
        name: customer.Name,
        identity: customer.Identity,
        // birthdate: customer.,
        // phone: customer.,
        address: {
          State: customer.Address.State,
          City: customer.Address.City
        },
        identityType: customer.IdentityType
      },
      creditCard: {
        cardNumber: payment.CreditCard.CardNumber,
        holder: payment.CreditCard.Holder,
        securityCode: payment.CreditCard.SecurityCode,
        brand: payment.CreditCard.Brand,
        expirationDate: payment.CreditCard.ExpirationDate
      },
      installments: payment.Installments
    };

    return this.http.post<{purchase: Purchase, purchaseStatus, merchantOrderId}>(
      this.endpointsService.get('purchase.purchase'),
      body,
      { headers: new HttpHeaders({ 'Recaptcha-Token': recaptcha }) },
    );
  }

  setLastPurchase(plan: Plan, program: TrainingProgramBasicInfo, customer: Customer, payment: Payment) {
    this.lastPurchase = {
      plan,
      program,
      customer,
      payment,
    };
  }

  getLastPurchase() {
    return this.lastPurchase;
  }

  validateCardBrand(cardNumber: string): Observable<{brand: string | null}> {
    return this.http.post<{brand: string | null}>(this.endpointsService.get('purchase.validateCardBrand'), {
      cardNumber
    });
  }
}

interface Purchase {
  customer: Customer;
  id: number;
  merchantOrderId: number;
  payment: Payment;
}
